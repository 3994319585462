.label {
	color: var(--oc-palette-text-primary);
	margin-bottom: 6px;
	font-size: 14px;
}

.externalAdornmentContainer {
	border: 1px solid var(--oc-palette-secondary-200);
	color: var(--oc-palette-onLight-secondary);
	padding: 8px 12px;
	height: 40px;
	display: inline-flex;

	&:global(.start) {
		border-radius: var(--oc-spacing) 0 0 var(--oc-spacing);
		border-right: none;
	}

	&:global(.end) {
		border-radius: 0 var(--oc-spacing) var(--oc-spacing) 0;
		border-left: none;
	}

	&:global(.multiline) {
		height: inherit;
	}
}

.helperText {
	font-size: 14px;
	margin-top: var(--oc-spacing);
	max-width: 280px;
	color: var(--oc-palette-text-primary);

	&:global(.error) {
		color: var(--oc-palette-error-main);
	}

	&:global(.secondary) {
		color: var(--oc-palette-text-secondary);
	}

	&:global(.warn) {
		color: var(--oc-palette-warning-main);
	}

	&:global(.info) {
		color: var(--oc-palette-info-main);
	}

	&:global(.success) {
		color: var(--oc-palette-success-main);
	}
}
