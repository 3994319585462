/* these should align with items in OpteraTheme file - before adding new one consider adding in theme instead */
/* theme palette colors along with other theme vars are injected to the global root scope by MUI */
:root {
	/* Rebrand colors */
	--oc-palette-primary-box-shadow: rgba(77, 129, 55, 0.24);
	--oc-palette-error-box-shadow: rgba(240, 68, 56, 0.24);
	--oc-palette-warning-lighter: #fff6eb;
	--oc-palette-secondary-100: rgba(
		251,
		251,
		251,
		1
	); /* also optera background light grey */
	--oc-palette-onLight-primary: rgba(14, 26, 27, 0.87);
	--oc-palette-onLight-secondary: rgba(14, 26, 27, 0.6);
	--oc-palette-dark-text-secondary: rgba(255, 255, 255, 0.68);
	--oc-palette-dark-text-primary: rgba(255, 255, 255, 0.99);
	--oc-palette-sky-blue-100: rgba(207, 243, 254, 1);
	--oc-palette-neutral-400: rgba(157, 173, 174, 1);
	--oc-palette-neutral-500: rgba(102, 121, 122, 1);
	--oc-palette-gray-200: rgba(228, 231, 236, 1);
	--oc-palette-gray-500: rgba(102, 112, 133, 1);
	--oc-palette-action-selected: rgba(
		14,
		26,
		27,
		0.08
	); /* Only used in the nav right now */
	--oc-spacing2: calc(var(--oc-spacing) * 2);
	--oc-spacing3: calc(var(--oc-spacing) * 3);
	--oc-spacing4: calc(var(--oc-spacing) * 4);
	--oc-spacing5: calc(var(--oc-spacing) * 5);
	--oc-spacing6: calc(var(--oc-spacing) * 6);
}

/* Next will insert anchor tags in it's Link tags - these are not necessarily meant to look like 'links' */
a {
	color: inherit;
	text-decoration: none;
}

::selection {
	background-color: var(--oc-palette-primary-500);
	color: #fff;
}

/* aligned with Typography in theme file */
p {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	overflow-wrap: break-word;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	margin: 0;
	text-wrap: balance;
}
h1,
.text-xxxxl {
	font: var(--oc-font-h1);
	letter-spacing: -1.5px;
}
h2,
.text-xxxl {
	font: var(--oc-font-h2);
	letter-spacing: -0.5px;
}
h3,
.text-xxl {
	font: var(--oc-font-h3);
}
h4,
.text-xl {
	font: var(--oc-font-h4);
}
h5 {
	font: var(--oc-font-h5);
}
h6,
.text-lg {
	font: var(--oc-font-h6);
}
.body1 {
	font: var(--oc-font-body1);
}
.body2 {
	font: var(--oc-font-body2);
	letter-spacing: 0.15px;
}
.subtitle1 {
	font: var(--oc-font-subtitle1);
}
.subtitle2 {
	font: var(--oc-font-subtitle2);
	letter-spacing: 0.1px;
}
.caption {
	font-size: 12px;
	font-weight: 400;
	line-height: 15.6px;
}
.overline {
	font: var(--oc-font-overline);
}
.chip {
	font: var(--oc-font-chip);
}
.small {
	font: var(--oc-font-small);
}
/* All links should use these classes. More info here https://www.figma.com/design/cLEGlWUKZ50hxdmXomNNcD/%E2%9C%85-Link-Updates?node-id=0-1&t=x1rWoGvXHfDF07oH-0 */
.link-text1 {
	text-decoration: underline;
	cursor: pointer;
	color: var(--oc-palette-text-primary);
}
.link-text1:hover {
	text-decoration: none;
	color: var(--oc-palette-primary-500);
}
.link-text2 {
	text-decoration: underline;
	cursor: pointer;
	color: var(--oc-palette-text-secondary);
}
.link-text2:hover {
	color: var(--oc-palette-primary-400);
}
button.link {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	margin: 0;
	padding: 0;
}
.text-primary {
	color: var(--oc-palette-text-primary);
}
.text-secondary {
	color: var(--oc-palette-text-secondary);
}
.text-disabled {
	color: var(--oc-palette-text-disabled);
}
.text-success {
	color: var(--oc-palette-success-main);
}
.text-error {
	color: var(--oc-palette-error-main);
}
.text-info {
	color: var(--oc-palette-info-main);
}
.text-warning {
	color: var(--oc-palette-warning-main);
}
.text-p500 {
	color: var(--oc-palette-primary-500);
}
pre,
code {
	color: var(--oc-palette-text-primary);
	font-family: "IBM Plex Mono", monospace;
	font-size: 12px;
	font-weight: 500;
	line-height: 17.16px;
}

/* aligned with Table in MUI */
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}
tr {
	vertical-align: middle;
	outline: 0;
}
th,
td {
	font: var(--oc-font-body2);
	letter-spacing: 0.15px;
	vertical-align: inherit;
	border-bottom: 1px solid var(--oc-palette-TableCell-border);
	text-align: left;
	padding: var(--oc-spacing2);
}
thead th {
	line-height: 1.5rem;
	font-weight: 500;
}
tbody tr:last-child td {
	border: 0;
}
table.striped > tbody > tr:nth-child(odd) {
	background-color: var(--oc-palette-action-hover);
}
table.highlight > tbody > tr:hover {
	background-color: var(--oc-palette-action-hover);
}
table.stickyHeader th {
	vertical-align: inherit;
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: var(--oc-palette-background-default);
	border-bottom: 1px solid var(--oc-palette-TableCell-border); /* doesn't seem to actually apply */
	box-shadow: 0 1px 0 var(--oc-palette-TableCell-border);
}
hr {
	flex-shrink: 0;
	border-width: 0;
	border-style: solid;
	border-color: var(--oc-palette-divider);
	border-bottom-width: thin;
	margin: 0;
}

p.disabled,
span.disabled,
div.disabled,
label.disabled,
li.disabled {
	color: var(--oc-palette-text-disabled);
}
