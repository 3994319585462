*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	min-height: 100vh;
}

input,
button,
textarea,
select {
	font-family: inherit;
	font-size: inherit;
}

iframe {
	border: none;
}
