@import url("reset.css");
@import url("muiThemeAlign.css");
@import url("utilities.css");

* {
	font-variant-numeric: tabular-nums !important;
}

html,
body {
	font-family:
		Inter,
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		Fira Sans,
		Droid Sans,
		Helvetica Neue,
		sans-serif;
}

progress[value] {
	appearance: none; /* removes browser default styling */
	background-color: var(--oc-palette-progressBar-main);
	border: none;
	height: 8px;
}

progress[value]::-webkit-progress-bar {
	background-color: var(--oc-palette-progressBar-main);
	height: 8px;
}

progress[value]::-webkit-progress-value {
	background-color: var(--oc-palette-primary-300);
	border-radius: 4px;
}

progress[value]::-moz-progress-bar {
	background-color: var(--oc-palette-primary-300);
	border-radius: 4px;
}

progress[value].orange::-webkit-progress-value {
	background-color: var(--oc-palette-warning-main);
}

progress[value].orange::-moz-progress-bar {
	background-color: var(--oc-palette-warning-main);
}

progress[value].yellow::-webkit-progress-value {
	background-color: var(--oc-palette-optera-yellow);
}

progress[value].yellow::-moz-progress-bar {
	background-color: var(--oc-palette-optera-yellow);
}

progress[value].red::-webkit-progress-value {
	background-color: var(--oc-palette-error-main);
}

progress[value].red::-moz-progress-bar {
	background-color: var(--oc-palette-error-main);
}

button.menuOption {
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	color: inherit;
	text-align: left;
	cursor: pointer; /* Indicates that the element is interactive */
	display: inline;
}

code.tag {
	padding: 4px 8px;
	border-radius: 4px;
	background-color: var(--oc-palette-secondary-200);
	width: max-content;
	cursor: pointer;
}

code.tag:hover {
	background-color: var(--oc-palette-secondary-300);
}

/* --begin--: broad component-ish styles that don't have a clear home in a module.css file */

.chart-tip-text {
	font: var(--oc-font-body2);
	letter-spacing: 0.15px;
	color: var(--oc-palette-onLightText-main);
}
.chart-tip-text2 {
	font-size: 14px;
	color: var(--oc-palette-onLightText-main);
}

.modalPage-closeIcon {
	position: fixed;
	font-size: 56px;
	top: 32px;
	right: 32px;
	cursor: pointer;
	color: var(--oc-palette-lineMetric-neutral);
}

.survey-formStack {
	display: flex;
	flex-direction: column;
	max-width: 1440px;
	margin: auto;
	padding: 0 min(128px, 12.5%) 128px;
}

.icon-hoverable:hover {
	fill: var(--oc-palette-neutral-700);
}

#opteraLayout-wrapper {
	display: grid;
	grid-template-rows: 56px 1fr;
	grid-template-columns: 70px 1fr;
	height: 100vh;
	transition: 200ms;

	&.nav-wide {
		grid-template-columns: 312px 1fr;
	}

	/* & > #appBar-nav, */
	& > nav:first-child {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
	}

	/* & > #leftRail-nav, */
	& > nav:nth-child(2) {
		grid-column-start: 1;
		grid-row-start: 2;
	}

	& > main {
		grid-column-start: 2;
		grid-row-start: 2;
		overflow-y: auto;
	}

	main > * {
		max-width: 1440px;
		margin: 0 auto;
	}

	.main-rail-expanded {
		width: calc(100vw - 312px);
	}

	.main-rail-collapsed {
		width: calc(100vw - 70px);
	}
}

/* --end--: broad component-ish styles that don't have a clear home in a module.css file */
